import fetch from "cross-fetch";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { PULSE } from "./addresses";

const apolloOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
  watchQuery: {
    fetchPolicy: "no-cache",
  },
};

const pulseStatsClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://sub21.phatty.io/subgraphs/name/phamous-stats",
    fetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: apolloOptions,
});

function getStatsClient(chainId) {
  if (chainId === PULSE) {
    return pulseStatsClient;
  }
  throw new Error(`Invalid chainId ${chainId}`);
}

const pulsePricesClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://sub21.phatty.io/subgraphs/name/phamous-prices",
    fetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: apolloOptions,
});

function getPricesClient(chainId) {
  if (chainId === PULSE) {
    return pulsePricesClient;
  } else {
    throw new Error(`Invalid chainId ${chainId}`);
  }
}

const pulsePositionsClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://sub21.phatty.io/subgraphs/name/phamous-positions",
    fetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: apolloOptions,
});

function getPositionsClient(chainId) {
  if (chainId === PULSE) {
    return pulsePositionsClient;
  } else {
    throw new Error(`Invalid chainId ${chainId}`);
  }
}

const ensSubgraphClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ensdomains/ens',
    fetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: apolloOptions,
});

const pnsSubgraphClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph.pulse.domains/subgraphs/name/graphprotocol/pns',
    fetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: apolloOptions,
});

module.exports = {
  getPricesClient,
  getStatsClient,
  getPositionsClient,
  ensSubgraphClient,
  pnsSubgraphClient
};
